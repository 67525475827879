import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import yay from "../assets/help.jpg";

const OurWork = () => {
  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img src={yay} className="min-w-full object-cover object-top" alt="" />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 className="title-font sm:text-4xl text-3xl font-medium text-white drop-shadow">
            Our Work
          </h1>
          <span className="mb-4 leading-tight text-1xl drop-shadow block text-white">
            How we're offering grants, financial education and money management
            tools
          </span>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 className="sm:text-3xl text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            How we're helping
          </h2>
          <div className="md:w-3/5 md:pl-6">
            <p className="leading-relaxed text-base mb-4">
              Madad UK (MUK) is working alongside{" "}
              <a
                href="https://zeropa.co.uk?ref=madad"
                target="_blank"
                rel="noreferrer"
                className="text-indigo-500 hover:text-indigo-700"
              >
                ZeroPA
              </a>{" "}
              to offer financial education and money management tools.
            </p>
            <p className="leading-relaxed text-base mb-4">
              <a
                href="https://cafdonate.cafonline.org/27816#!/DonationDetails"
                target="_blank"
                rel="noreferrer"
                className="text-indigo-500 hover:text-indigo-700"
              >
                Our Ramadan campaign
              </a>{" "}
              will help us raise much needed funds towards supporting more
              families of all faiths and none with grants in the form of
              vouchers of up to &pound;50 towards food, utilities and clothing.
            </p>
            <p className="leading-relaxed text-base">
              The UK Muslim community along with many ethnic minority
              populations in general face significant financial challenges, as
              highlighted by recent statistics:
              <ol className="ml-8 mt-8 mb-8 list-decimal">
                <li>
                  <span className="font-bold">Poverty Levels</span>:
                  Approximately 50% of British Muslims live in poverty, compared
                  to 18% of the general population.{" "}
                </li>
                <li>
                  <span className="font-bold">Food Bank Usage</span>: One in
                  five British Muslims has relied on food banks in the past year
                  due to rising living costs.{" "}
                </li>
                <li>
                  <span className="font-bold">Debt Accumulation</span>: Since
                  August 2021, 65% of Muslims have incurred some form of debt to
                  manage everyday expenses and bills.
                </li>
                <li>
                  <span className="font-bold">Unemployment Rates</span>: In
                  2021, the unemployment rate among Muslims in England and Wales
                  was 6.7%, the highest among religious groups, and notably
                  higher than the 3.8% rate among Christians.
                </li>
                <li>
                  <span className="font-bold">Geographical Disparities</span>:
                  About 68% of Muslims in England and Wales reside in areas with
                  the highest unemployment rates, indicating a concentration in
                  economically deprived regions.{" "}
                </li>
              </ol>
            </p>
            <p className="leading-relaxed text-base">
              Additionally this has an impact on health, current illness and
              conditions, and future health outcomes due to interwined factors
              such as employment type, housing, educational attainment and other
              related factors.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default OurWork;
