import mtn from "../../assets/mytennights.svg";

const TenNights = () => {
  return (
    <>
      <div className="w-80 inline-flex items-center justify-center flex-shrink-0 mt-4 mb-8">
        <img
          src={mtn}
          alt="My Ten Nights logo"
          title="My Ten Nights - Never Miss Laylatul Qadr"
          className="mtn-logo"
          style={{ margin: "0 auto" }}
        />
      </div>
      <h2 className="text-white text-2xl title-font font-medium mb-3">
        Never Miss Laylatul Qadr
      </h2>
      <p className="leading-relaxed text-base md:w-1/2 mx-auto text-blue-100">
        We've partnered with My Ten Nights who have raised over $100m for
        excellent charities such-as Orphans In Need, Muslim Aid and Charity
        Water. With the My Ten Nights platform, you can automate your donations
        across Ramadan. If you are able, we would appreciate a donation to Madad
        to help those in need.
      </p>
      <a
        href="http://www.mytennights.com/madad-uk-muk-uk"
        target="_blank"
        rel="noreferrer"
        className="flex mx-auto mt-8 text-blue-800 bg-indigo-200 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-300 rounded text-1xl animate-pulse"
      >
        Make a donation
      </a>
    </>
  );
};

export default TenNights;
