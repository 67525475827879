import { useEffect } from "react";

const Ramadan = () => {
  useEffect(() => {
    window.location.replace(
      `https://cafdonate.cafonline.org/27816#!/DonationDetails`
    );
  }, []);
};

export default Ramadan;
