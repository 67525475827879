import Footer from "../components/Footer/Footer";
import TransparentHeader from "../components/TransparentHeader/TransparentHeader";
import Wallpaper from "../components/Wallpaper/Wallpaper";
import FeatureBlock from "../components/FeatureBlock/FeatureBlock";
import PictureSlice from "../components/PictureSlice/PictureSlice";
import { useNavigate } from "react-router-dom";
import QuoteBlock from "../components/QuoteBlock/QuoteBlock";
import TenNights from "../components/TenNights/TenNights";
import TestimonialBlock from "../components/TestimonialBlock/TestimonialBlock";

const Home = () => {
  let navigate = useNavigate();

  const pictureSliceHandler = () => {
    navigate("/apply", { replace: false });
  };

  return (
    <>
      <div className="p-4 pt-16 pb-16 flex flex-col text-center items-center bg-gradient-to-t from-blue-800 to-indigo-900">
        <TenNights />
      </div>
      <TransparentHeader />
      <Wallpaper />
      <FeatureBlock />
      <QuoteBlock />
      <PictureSlice
        slice="point"
        title="Get control of your finances"
        subtitle="We're here to help you improve your credit rating and get your finances in order."
        withButton
        buttonText="Learn more"
        buttonHandler={pictureSliceHandler}
      />
      <TestimonialBlock />
      {/* <AppStoreCTA /> */}
      <Footer />
    </>
  );
};

export default Home;
