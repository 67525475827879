/* eslint-disable jsx-a11y/img-redundant-alt */
const TestimonialBlock = () => {
  return (
    <>
      <section className="text-white bg-purple-800">
        <div className="container lg:px-10 py-10 mx-auto w-3/5">
          <div className="text-center mb-2 text-lg flex flex-col items-center">
            <div className="mb-8">
              <img
                class="w-20 h-20 rounded-full"
                src="https://images.unsplash.com/photo-1593355660385-43a0471c6077?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                alt="I.S. profile picture"
              />
            </div>
            <span className="text-base lg:text-lg leading-relaxed font-medium italic">
              "The ZeroPA scheme has been useful as I've been able to find a
              interest free repayment scheme which is very hard to find these
              days given that we are in a time where interest is very prevalent.
              The vouchers have been beneficial as I've been able to buy new
              headscarves and also I have been able to do shopping at Tesco
              without the stress of the whole repayment scheme, since this
              scheme is interest-free. It also has been beneficial as I have
              time to pay back the loan too which is something we agree upon
              before signing the 'contract' too."
            </span>
            <span className="block text-base mt-8">I.S. March 2025</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialBlock;
